import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Features and Reports Tour`}</h1>
    <p>{`Select any of the options below, or from the menu, to learn more about Dashbot’s features and reports including:`}</p>
    <h2><a parentName="h2" {...{
        "href": "/tour/conversational-analytics"
      }}>{`Conversational Analytics`}</a></h2>
    <ul>
      <li parentName="ul">{`Phrase Clusters`}</li>
      <li parentName="ul">{`Message, utterance, and interaction funnels`}</li>
      <li parentName="ul">{`NLP / Intents `}{`&`}{` Entities`}</li>
      <li parentName="ul">{`Intent funnels`}</li>
      <li parentName="ul">{`Intent mappings`}</li>
      <li parentName="ul">{`Unhandled Intents`}</li>
      <li parentName="ul">{`Transcripts – real time and archived`}</li>
      <li parentName="ul">{`Sentiment analysis`}</li>
      <li parentName="ul">{`Message and utterance word cloud clustering`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "/tour/engagement"
      }}>{`Engagement `}{`&`}{` Retention`}</a></h2>
    <ul>
      <li parentName="ul">{`Daily, weekly, and monthly cohort retention`}</li>
      <li parentName="ul">{`Sessions per user`}</li>
      <li parentName="ul">{`Time per session`}</li>
      <li parentName="ul">{`Messages/interactions per session`}</li>
      <li parentName="ul">{`Active, engaged, and returning users`}</li>
      <li parentName="ul">{`Lifetime users`}</li>
      <li parentName="ul">{`Sessions`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "/tour/behavior"
      }}>{`User Behavior`}</a></h2>
    <ul>
      <li parentName="ul">{`Top messages, utterances, interactions, and Intents`}</li>
      <li parentName="ul">{`Top exit messages`}</li>
      <li parentName="ul">{`Message, utterance, interaction, and Intent funnels`}</li>
      <li parentName="ul">{`Goal Funnels`}</li>
      <li parentName="ul">{`Escalations / Containment`}</li>
      <li parentName="ul">{`Goal Conversions`}</li>
      <li parentName="ul">{`Conversation Paths`}</li>
      <li parentName="ul">{`Behavioral Flows`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "/tour/audience-demographics"
      }}>{`Audience Demographics`}</a></h2>
    <ul>
      <li parentName="ul">{`Top users`}</li>
      <li parentName="ul">{`Locations / locales`}</li>
      <li parentName="ul">{`Genders`}</li>
      <li parentName="ul">{`Languages`}</li>
      <li parentName="ul">{`Timezones`}</li>
      <li parentName="ul">{`Device Capabilities`}</li>
      <li parentName="ul">{`Slack teams`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "/tour/comparison-metrics"
      }}>{`Comparison Metrics`}</a></h2>
    <ul>
      <li parentName="ul">{`Cross channel metrics`}</li>
      <li parentName="ul">{`Benchmarking based on users and messages/interactions`}</li>
      <li parentName="ul">{`Alexa store rankings comparisons`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "/tour/tools"
      }}>{`Tools to Take Action`}</a></h2>
    <ul>
      <li parentName="ul">{`Alerts`}</li>
      <li parentName="ul">{`Live person takeover`}</li>
      <li parentName="ul">{`Audience Segmentation`}</li>
      <li parentName="ul">{`Broadcast messaging`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "/tour/multiuser"
      }}>{`Multi User Access`}</a></h2>
    <ul>
      <li parentName="ul">{`Multi-user / Multi-client`}</li>
      <li parentName="ul">{`Cross Client Comparison`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      